<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-center mt-4"
      style="margin-bottom: 2%"
    >
      <div class="d-flex align-items-center">
        <input
          type="text"
          class="form-control me-2"
          v-model="buildQuantity"
          placeholder="Enter Build Quantity"
          style="width: auto"
          @keypress.enter="fetchNewUnitPrices"
        />
        <button class="btn btn-primary" @click="fetchNewUnitPrices">
          Fetch Unit Prices
        </button>
      </div>

      <button @click="onBtExport" class="btn btn-primary">
        <i class="fas fa-download me-2"></i>Download CSV
      </button>
    </div>

    <div class="grand-total">
      <span class="footer-label">Grand Total: </span>
      <span class="footer-value">${{ grandTotal }}</span>
    </div>

    <ag-grid-vue
      style="height: 500px"
      class="ag-theme-quartz"
      :rowData="localBom"
      :defaultColDef="defaultColDef"
      :columnDefs="colDefs"
      :pagination="true"
      @rowClicked="onRowClicked"
      @cellValueChanged="onCellValueChanged"
      :frameworkComponents="{ editButtonRenderer: editButtonRenderer }"
      ref="agGrid"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue3";
import axios from "axios";

export default {
  name: "BomItemDetails",
  components: {
    AgGridVue,
  },
  props: {
    bom: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      grandTotal: 0,
      buildQuantity: "",
      originalQuantities: [], // Store original quantities here
      updatedBom: [],
      localBom: [],
      colDefs: [
        { headerName: "ID", field: "id", hide: true },
        { headerName: " Level", field: "level" },
        { headerName: "VEPL Part Number", field: "part_number" },
        { headerName: "Priority Level", field: "priority_level" },
        { headerName: "Value", field: "value" },
        { headerName: "PCB Footprint", field: "pcb_footprint" },
        { headerName: "Type", field: "line_item_type.name" },
        { headerName: "Description", field: "description" },
        {
          headerName: "References",
          valueGetter: function (params) {
            const references = params.data.references;
            if (references && references.length > 0) {
              const referenceNames = references.map(
                (reference) => reference.name
              );
              return referenceNames.join(", ");
            }
            return "";
          },
        },
        {
          headerName: "Manufacturer Parts",
          valueGetter: function (params) {
            const manufacturerParts = params.data.manufacturer_parts;
            if (manufacturerParts && manufacturerParts.length > 0) {
              const partStrings = manufacturerParts.map((manufacturerPart) => {
                const manufacturer = manufacturerPart.manufacturer;
                if (manufacturer) {
                  return `${manufacturerPart.part_number} - ${manufacturer.name}`;
                }
                return "";
              });
              return partStrings.join(", ");
            }
            return "";
          },
        },
        { headerName: "Customer Part Number", field: "customer_part_number" },
        { headerName: "Quantity", field: "quantity" },
        {
          headerName: "Required Quantity",
          field: "required_quantity",

          valueSetter: (params) => {
            const newValue = parseFloat(params.newValue) || 0;
            params.data.required_quantity = newValue;
            params.data.total_price =
              newValue * (parseFloat(params.data.unit_price) || 0);
            return true;
          },
        },
        {
          headerName: "Unit Price",
          field: "unit_price",
          // editable: true,
          valueGetter: (params) => {
            return `$${parseFloat(params.data.unit_price).toFixed(2)}`;
          },
          valueSetter: (params) => {
            const newValue =
              parseFloat(params.newValue.replace(/^\$/, "")) || 0;
            params.data.unit_price = newValue;
            params.data.total_price =
              (parseFloat(params.data.required_quantity) || 0) * newValue;
            return true;
          },
        },
        {
          headerName: "Total Price",
          field: "total_price",
          valueGetter: (params) => {
            const requiredQuantity =
              parseFloat(params.data.required_quantity) || 0;
            const unitPrice = parseFloat(params.data.unit_price) || 0;
            return `$${(requiredQuantity * unitPrice).toFixed(2)}`;
          },
        },
        // -------------------------------------- added 3 cols -----------------------

        {
          headerName: "Freight",
          field: "freight_percent_price",
          valueGetter: (params) => {
            const freightValue =
              this.customColumnCalculation(params)?.Freight_value || 0; //  access Freight_value
            const freight = parseFloat(freightValue).toFixed(2); // ensure it's a number before calling toFixed
            console.log("freight value in aggrid:", freight);
            return `$${freight}`;
          },
        },
        {
          headerName: "Duty",
          field: "duty",
          valueGetter: (params) => {
            const dutyValue =
              this.customColumnCalculation(params)?.Duty_value || 0;
            const duty = parseFloat(dutyValue).toFixed(2);
            console.log("duty value in aggrid:", duty);
            return `$${duty}`;
          },
        },
        {
          headerName: "GST",
          field: "gst",
          valueGetter: (params) => {
            const gstValue =
              this.customColumnCalculation(params)?.GST_value || 0;
            const gst = parseFloat(gstValue).toFixed(2);
            console.log("gst value in aggrid:", gst);
            return `$${gst}`;
          },
        },
        {
          headerName: "Total Price With GST",
          field: "total_price_with_gst",
          valueGetter: (params) => {
            const totalPriceWithGstValue =
              this.customColumnCalculation(params)?.TotalPriceWithGst_value ||
              0;
            const totalPriceWithGst = parseFloat(
              totalPriceWithGstValue
            ).toFixed(2);
            console.log(
              "totalPriceWithGst value in aggrid:",
              totalPriceWithGst
            );
            return `$${totalPriceWithGst}`;
          },
        },

        { headerName: "UOM", field: "uom" },
        { headerName: "Assembly Stage", field: "assembly_stage.name" },
        { headerName: "ECN", field: "ecn" },
        { headerName: "MSL", field: "msl" },
        { headerName: "Remarks", field: "remarks" },
        { headerName: "Api Remarks", field: "api_status" },
      ],
      defaultColDef: {
        filter: true,
        sortable: true,
        resizable: true,
        autoSize: true,
      },
      selectedRows: [],
    };
  },
  // mounted() {
  //   console.log("Component mounted. BOM Data:", this.bom);
  //   this.storeOriginalQuantities();
  //   this.calculateGrandTotal();
  //   if (Array.isArray(this.bom)) {
  //     this.localBom = [...this.bom]; // Copy bom to localBom
  //     this.storeOriginalQuantities();
  //     this.calculateGrandTotal();
  //   } else {
  //     console.error("The 'bom' prop is not an array or is undefined.");
  //     this.localBom = [];
  //   }
  // },
  mounted() {
    console.log("Component mounted. BOM Data:", this.bom);

    // Store original quantities and prepare BOM for calculation
    this.storeOriginalQuantities();

    if (Array.isArray(this.bom)) {
      this.localBom = [...this.bom]; // Copy bom to localBom

      // Perform initial calculations for all rows
      this.localBom.forEach((row) => {
        this.computeRowTotals(row);
      });

      // Calculate overall totals
      this.calculateGrandTotal();
    } else {
      console.error("The 'bom' prop is not an array or is undefined.");
      this.localBom = [];
    }
  },
  watch: {
    buildQuantity(newQuantity) {
      if (newQuantity === "") {
        this.restoreOriginalQuantities();
      } else {
        this.updateRequiredQuantities();
      }
    },
    bom: {
      handler() {
        this.calculateGrandTotal();
      },
      deep: true,
    },
  },
  methods: {
    computeRowTotals(row) {
      // Calculate total price
      const requiredQuantity = parseFloat(row.required_quantity) || 0;
      const unitPrice = parseFloat(row.unit_price) || 0;
      row.total_price = requiredQuantity * unitPrice;

      // Calculate freight, duty, and GST
      const freightPercent = 0.12; // 12%
      const dutyPercent = 0.11; // 11%
      const activeComponents = [
        "IC",
        "MOSFET",
        "DIODE",
        "LED",
        "DISPLAY",
        "BATTERY",
        "BATT",
        "OSCILATOR",
        "OSC",
        "CONN",
      ];

      const componentType = row["line_item_type"]?.name || "Unknown";
      const isActiveComponent = activeComponents.includes(
        componentType.toUpperCase()
      );

      row.freight_percent_price = row.total_price * freightPercent;
      row.duty = isActiveComponent ? row.total_price * dutyPercent : 0;
      row.gst = (row.total_price + row.freight_percent_price + row.duty) * 0.18; // 18%
      row.total_price_with_gst =
        row.total_price + row.freight_percent_price + row.duty + row.gst;
    },

    storeOriginalQuantities() {
      if (Array.isArray(this.bom)) {
        this.originalQuantities = this.bom.map((item) => ({
          id: item.id,
          required_quantity: item.required_quantity,
        }));
      } else {
        console.error("BOM data is not available or is not an array.");
      }
    },
    restoreOriginalQuantities() {
      if (Array.isArray(this.bom) && Array.isArray(this.originalQuantities)) {
        this.bom.forEach((item) => {
          const original = this.originalQuantities.find(
            (originalItem) => originalItem.id === item.id
          );
          if (original) {
            item.required_quantity = original.required_quantity;
          }
        });
        this.$refs.agGrid.api.refreshCells({
          columns: ["required_quantity", "total_price"],
        });
        this.calculateGrandTotal();
      }
    },
    updateRequiredQuantities() {
      const quantity = parseFloat(this.buildQuantity) || 0;

      // to store the new build quantity in the VueX Store
      this.$store.dispatch("updateLatestBuildData", quantity);

      this.bom.forEach((item) => {
        item.required_quantity = item.quantity * quantity;
      });
      this.$refs.agGrid.api.refreshCells({
        columns: ["required_quantity", "total_price"],
      });

      this.calculateGrandTotal();
    },

    // async fetchNewUnitPrices() {
    //   this.$store.commit("setIsLoading", true);
    //   try {
    //     console.log("Fetching unit prices...");

    //     // Fetch unit prices for each BOM item
    //     const responses = await Promise.all(
    //       this.localBom.map((item) => {
    //         const partNumbers = item.manufacturer_parts.map(
    //           (part) => part.part_number
    //         );
    //         return axios.get("/pricing/get-unit-prices", {
    //           params: {
    //             veplNumber: item.part_number,
    //             partNumber: partNumbers,
    //             requiredQuantity: item.required_quantity,
    //             distributor: "digikey",
    //           },
    //         });
    //       })
    //     );

    //     // Update localBom with new prices
    //     responses.forEach((response, index) => {
    //       console.log(`Response for BOM item ${index}:`, response.data);

    //       const unitPrices = response.data;
    //       const minPricePart =
    //         unitPrices["Overall Manufacturer Part with Minimum Price"];

    //       if (minPricePart) {
    //         const { Partnumber, "Unit Price": unitPrice } = minPricePart;
    //         this.localBom[index].manufacturer_parts.forEach((item) => {
    //           if (item.part_number === Partnumber) {
    //             console.log(
    //               `Updating part number ${item.part_number} with unit price ${unitPrice}`
    //             );
    //             item.unit_price = unitPrice || 0; // Ensure default value
    //             item.total_price =
    //               (item.required_quantity || 0) * item.unit_price;
    //           }
    //         });

    //         this.localBom[index].unit_price = unitPrice;
    //         this.localBom[index].total_price =
    //           (this.localBom[index].required_quantity || 0) * unitPrice;
    //       }
    //     });

    //     // Set updated rowData and refresh grid cells
    //     if (this.$refs.agGrid && this.$refs.agGrid.api) {
    //       this.$refs.agGrid.api.setRowData(this.localBom);
    //       this.$refs.agGrid.api.refreshCells({
    //         columns: ["unit_price", "total_price"],
    //         force: true, // Force refresh to ensure changes are visible
    //       });
    //     }
    //   } catch (error) {
    //     console.error("Error fetching unit prices:", error);
    //   } finally {
    //     this.$store.commit("setIsLoading", false);
    //   }

    //   this.calculateGrandTotal();
    // },

    //   ---------------------- OLD CODE -------------------------x

    async fetchNewUnitPrices() {
      const BATCH_SIZE = 10; // Adjust the batch size as needed
      this.$store.commit("setIsLoading", true);

      try {
        console.log("Fetching unit prices in batches...");

        // Break localBom into batches
        const batchedBom = [];
        for (let i = 0; i < this.localBom.length; i += BATCH_SIZE) {
          batchedBom.push(this.localBom.slice(i, i + BATCH_SIZE));
        }

        // Process each batch sequentially
        for (const batch of batchedBom) {
          const requests = batch.map((item) => {
            const partNumbers = item.manufacturer_parts.map(
              (part) => part.part_number
            );

            return axios
              .get("/pricing/get-unit-prices", {
                params: {
                  bomId: this.bom[1].bom.id,
                  veplNumber: item.part_number,
                  partNumber: partNumbers,
                  requiredQuantity: item.required_quantity,
                  distributor: "digikey",
                },
              })
              .then((response) => ({
                status: "fulfilled",
                value: response.data,
                index: this.localBom.findIndex((bom) => bom === item), // Use global index for localBom
              }))
              .catch((error) => ({
                status: "rejected",
                reason: error,
                index: this.localBom.findIndex((bom) => bom === item),
              }));
          });

          // Wait for all API requests in the batch to settle
          const results = await Promise.allSettled(requests);

          // console.log(" the Response");

          // Iterate over the results and update localBom
          results.forEach((result) => {
            if (result.status === "fulfilled") {
              const { value, index } = result.value;

              if (
                value &&
                value["Overall Manufacturer Part with Minimum Price"]
              ) {
                const minPricePart =
                  value["Overall Manufacturer Part with Minimum Price"];

                // Correct the property names here
                const partNumber = minPricePart["Part Number"]; // Ensure key name matches API response
                const unitPrice = minPricePart["Unit Price"]; // Ensure key name matches API response

                // Update the localBom array with the new prices
                const bomItem = this.localBom[index];
                if (bomItem) {
                  bomItem.manufacturer_parts.forEach((part) => {
                    if (part.part_number === partNumber) {
                      // Correct comparison key
                      console.log(
                        `Updating part number ${part.part_number} with unit price ${unitPrice}`
                      );
                      part.unit_price = unitPrice || 0; // Ensure default value
                      part.total_price =
                        (part.required_quantity || 0) * part.unit_price;
                    }
                  });
                  bomItem.unit_price = unitPrice;
                  bomItem.total_price =
                    (bomItem.required_quantity || 0) * unitPrice;

                  bomItem.api_status = `Unit Price Updated for Part Number (${partNumber})`; // Correct partNumber in message
                }
              } else {
                console.warn(`No data for part at index ${index}`);
                const bomItem = this.localBom[index];
                if (bomItem) {
                  const partErrors = value.Errors;
                  const partNumbers = bomItem.manufacturer_parts.map(
                    (part) => part.part_number
                  );

                  const errorMessages = partNumbers
                    .map((partNumber) =>
                      partErrors?.[partNumber]
                        ? `Error for ${partNumber}: ${partErrors[partNumber]}`
                        : null
                    )
                    .filter((msg) => msg !== null);

                  bomItem.api_status =
                    errorMessages.length > 0
                      ? errorMessages.join("; ")
                      : "No specific error message, Part Numbers not found";
                }
              }
            } else {
              console.error(
                `Error fetching unit price for BOM item at index ${result.reason.index}:`,
                result.reason
              );

              const bomItem = this.localBom[result.reason.index];
              if (bomItem) {
                bomItem.api_status = "Failure";
              }
            }
          });

          // Update ag-Grid after processing the batch
          if (this.$refs.agGrid && this.$refs.agGrid.api) {
            this.$refs.agGrid.api.setRowData(this.localBom);
            this.$refs.agGrid.api.refreshCells({
              columns: ["unit_price", "total_price"],
              force: true,
            });
          }
        }
      } catch (error) {
        console.error("Unexpected error fetching unit prices:", error);
      } finally {
        this.$store.commit("setIsLoading", false);
      }

      this.calculateGrandTotal();
    },

    //  ----------------- new code --------------------------

    // async fetchNewUnitPrices() {
    //   this.$store.commit("setIsLoading", true); // Show loading spinner
    //   const batchSize = 10; // Customize the batch size as needed
    //   const batches = [];

    //   // Split localBom into batches
    //   for (let i = 0; i < this.localBom.length; i += batchSize) {
    //     batches.push(this.localBom.slice(i, i + batchSize));
    //   }

    //   try {
    //     console.log(`Processing ${batches.length} batches...`);

    //     for (const batch of batches) {
    //       // Prepare the payload for the current batch
    //       const payload = {
    //         parts: batch.map((item) => ({
    //           veplNumber: item.part_number,
    //           partNumber: item.manufacturer_parts.map(
    //             (part) => part.part_number
    //           ),
    //           requiredQuantity: item.required_quantity,
    //           distributor: "digikey",
    //         })),
    //       };

    //       // Make the API call for the batch
    //       const response = await axios.post(
    //         "/pricing/get-unit-prices/",
    //         payload
    //       );
    //       const results = response.data;

    //       // Process the API response
    //       results.forEach((result) => {
    //         const matchBomItem = (result) => {
    //           if (!result["Vepl Number"] || result["Vepl Number"] === "") {
    //             const minPricePartNumber =
    //               result["Overall Manufacturer Part with Minimum Price"]
    //                 ?.Partnumber;
    //             const errorPartNumber = Object.keys(result.Errors || {})[0];
    //             const partNumberToMatch = minPricePartNumber || errorPartNumber;

    //             return this.localBom.find((item) =>
    //               item.manufacturer_parts.some(
    //                 (part) => part.part_number === partNumberToMatch
    //               )
    //             );
    //           } else {
    //             return this.localBom.find(
    //               (item) => item.part_number === result["Vepl Number"]
    //             );
    //           }
    //         };

    //         const bomItem = matchBomItem(result);
    //         if (bomItem) {
    //           // Update the BOM item with the API response
    //           bomItem.unit_price =
    //             result["Overall Manufacturer Part with Minimum Price"]?.[
    //               "Unit Price"
    //             ] || 0;
    //           bomItem.total_price =
    //             result["Overall Manufacturer Part with Minimum Price"]?.[
    //               "Total Price"
    //             ] || 0;
    //           bomItem.api_status = result.Errors
    //             ? `Error: ${JSON.stringify(result.Errors)}`
    //             : "Prices Updated Successfully";
    //         }
    //       });

    //       // Update the grid with batch data
    //       if (this.$refs.agGrid && this.$refs.agGrid.api) {
    //         this.$refs.agGrid.api.setRowData(this.localBom);
    //         this.$refs.agGrid.api.refreshCells({
    //           columns: ["unit_price", "total_price"],
    //           force: true,
    //         });
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error processing batches:", error);
    //   } finally {
    //     this.$store.commit("setIsLoading", false); // Hide loading spinner
    //   }

    //   this.calculateGrandTotal(); // Update the grand total
    // },

    //  --------------------------------------------------

    onCellValueChanged(params) {
      if (
        params.colDef.field === "quantity" ||
        params.colDef.field === "unit_price"
      ) {
        this.updateRequiredQuantities();
      }
      this.calculateGrandTotal();
    },
    calculateGrandTotal() {
      if (Array.isArray(this.bom)) {
        // total price without gst --------------
        // let total = 0;
        // this.bom.forEach((item) => {
        //   const requiredQuantity = parseFloat(item.required_quantity) || 0;
        //   const unitPrice = parseFloat(item.unit_price) || 0;
        //   total += requiredQuantity * unitPrice;
        // });
        // this.grandTotal = total.toFixed(2);

        // total price with freight duty gst ---------------
        let SumOftotalPriceWithGST = 0;
        this.bom.forEach((row) => {
          const componentType =
            row["line_item_type"] && row["line_item_type"].name
              ? row["line_item_type"].name
              : "Unknown";
          const totalPrice = parseFloat(row["total_price"] || 0);

          // Calculate Freight, Duty, and GST
          const freight_percent = 0.12; // 12%
          const duty_percent = 0.11; // 11%
          const activeComponents_Conn_arr = [
            "IC",
            "MOSFET",
            "DIODE",
            "LED",
            "DISPLAY",
            "BATTERY",
            "BATT",
            "OSCILATOR",
            "OSC",
            "CONN",
          ];
          const freight_price = totalPrice * freight_percent;
          const duty_price = activeComponents_Conn_arr.includes(
            componentType.toUpperCase()
          )
            ? totalPrice * duty_percent
            : 0;
          const gst_price = (totalPrice + freight_price + duty_price) * 0.18; // 18%
          const totalPriceGst =
            totalPrice + freight_price + duty_price + gst_price;
          SumOftotalPriceWithGST += totalPriceGst;
        });
        console.log(
          "sum of total price with gst -------",
          SumOftotalPriceWithGST
        );
        this.grandTotal = SumOftotalPriceWithGST.toFixed(2);
      } else {
        console.error("Expected bom to be an array, but got:", this.bom);
      }
    },
    onRowClicked(params) {
      this.$emit("rowClicked", params.data);
    },
    onRowSelected(params) {
      if (params.node.isSelected()) {
        this.$emit("rowSelected", params.node.data);
      }
    },
    editButtonRenderer(params) {
      const button = document.createElement("button");
      button.innerHTML = `<i class="fas fa-edit"></i>`;
      button.classList.add("btn", "btn-primary");
      button.addEventListener("click", () => this.onEditClick(params.data.id));
      return button;
    },
    onEditClick(id) {
      this.$router.push({ name: "BomLineItemEdit", params: { id } });
    },

    onBtExport() {
      console.log("The Download BOM sheet:", this.bom[0]?.bom?.bom_file_name);

      const csvData = this.$refs.agGrid.api.getDataAsCsv();

      const summaryHeader =
        "Component Type,Sum Of Quantity,Sum Of Unit Price,Sum Of Total Price,Sum Of Freight,Sum Of Duty,Sum Of GST, Sum Of Total Price With GST\n";

      const summaryData = this.calculateSummary();
      console.log("Summary data inside onBtExport():", summaryData);

      const latestBuildData = this.$store.state.latestBuildData;
      const buildQuantityEnteredByUser = `\nBUILD QUANTITY: ,${
        latestBuildData || "No Build Quantity Data"
      }\n`;
      console.log("The build quantity:", buildQuantityEnteredByUser);

      const totalQuantity = summaryData.reduce(
        (sum, item) => sum + item.Quantity,
        0
      );
      const totalUnitPrice = summaryData.reduce(
        (sum, item) => sum + item.UnitPriceSum,
        0
      );
      const totalPrice = summaryData.reduce(
        (sum, item) => sum + item.TotalPriceSum,
        0
      );
      const totalFreight = summaryData.reduce(
        (sum, item) => sum + item.freightSum,
        0
      );
      const totalDuty = summaryData.reduce(
        (sum, item) => sum + item.dutySum,
        0
      );
      const totalGst = summaryData.reduce((sum, item) => sum + item.gstSum, 0);
      const totalPriceWithGst = summaryData.reduce(
        (sum, item) => sum + item.totalPriceGstSum,
        0
      );

      const summaryDatas = summaryData
        .map(
          (item) =>
            `${item.Type},${item.Quantity},${item.UnitPriceSum},${item.TotalPriceSum},${item.freightSum},${item.dutySum},${item.gstSum},${item.totalPriceGstSum}`
        )
        .join("\n");

      const summaryTotalRow = `\n\nTOTAL:,${totalQuantity},$ ${totalUnitPrice.toFixed(
        2
      )},$ ${totalPrice.toFixed(2)},$ ${totalFreight.toFixed(
        2
      )},$ ${totalDuty.toFixed(2)},$ ${totalGst.toFixed(
        2
      )}, $ ${totalPriceWithGst.toFixed(2)}\n\n\n`;

      const summarySheetTitle = `\nSUMMARY SHEET: \n`;
      const pricingDetailsTitle = `\nPRICING DETAILS: \n`;

      const grandTotalCsv = `\n\nGRAND TOTAL:,$ ${totalPrice.toFixed(2)}\n`;
      const grandTotalWithGST = `\nGRAND TOTAL With GST:,$ ${totalPriceWithGst.toFixed(
        2
      )}\n`;

      const finalCsv =
        grandTotalCsv +
        grandTotalWithGST +
        buildQuantityEnteredByUser +
        summarySheetTitle +
        summaryHeader +
        summaryDatas +
        summaryTotalRow +
        pricingDetailsTitle +
        csvData;

      const blob = new Blob([finalCsv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      // Ensure the file name ends with .csv
      const fileName = this.bom[0]?.bom?.bom_file_name?.endsWith(".csv")
        ? this.bom[0].bom.bom_file_name
        : `${this.bom[0]?.bom?.bom_file_name || "default_file_name"}.csv`;

      link.setAttribute("href", url);
      link.setAttribute("download", fileName);

      // Fallback to open in a new tab if download doesn't work
      if (!link.download) {
        link.setAttribute("target", "_blank");
      }

      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // onBtExport() {
    //   // this.$refs.agGrid.api.exportDataAsCsv();

    //   // --------------------- to add grand total and summary sheet --------------------
    //   // Get existing data from AG Grid and grand total

    //   console.log(" the Download Bom sheet :", this.bom[0].bom.bom_file_name);
    //   const csvData = this.$refs.agGrid.api.getDataAsCsv();
    //   // const grandTotalCsv = `\n\nGRAND TOTAL:,$ ${this.grandTotal}\n`;
    //   // Create a summary table header
    //   const summaryHeader =
    //     "Component Type,Sum Of Quantity,Sum Of Unit Price,Sum Of Total Price,Sum Of Freight,Sum Of Duty,Sum Of GST, Sum Of Total Price With GST\n";

    //   // Calculate summary data
    //   const summaryData = this.calculateSummary(); // Assume this returns an array of summary objects
    //   console.log("summary data inside obtexport () ---", summaryData);

    //   // getting the latest Build quantity from store-vuex
    //   const latestBuildData = this.$store.state.latestBuildData;
    //   const buildQuantityEnteredByUser = `\nBUILD QUANTITY: ,${
    //     latestBuildData || "No Build Quantity Data"
    //   }\n`;
    //   console.log("the build qty =====", buildQuantityEnteredByUser);

    //   // Calculate grand totals for the summary
    //   const totalQuantity = summaryData.reduce(
    //     (sum, item) => sum + item.Quantity,
    //     0
    //   );
    //   const totalUnitPrice = summaryData.reduce(
    //     (sum, item) => sum + item.UnitPriceSum,
    //     0
    //   );
    //   const totalPrice = summaryData.reduce(
    //     (sum, item) => sum + item.TotalPriceSum,
    //     0
    //   );
    //   const totalFreight = summaryData.reduce(
    //     (sum, item) => sum + item.freightSum,
    //     0
    //   );
    //   const totalDuty = summaryData.reduce(
    //     (sum, item) => sum + item.dutySum,
    //     0
    //   );
    //   const totalGst = summaryData.reduce((sum, item) => sum + item.gstSum, 0);
    //   const SumOftotalPriceWithGst = summaryData.reduce(
    //     (sum, item) => sum + item.totalPriceGstSum,
    //     0
    //   );

    //   // Create summary rows
    //   const summaryDatas = summaryData
    //     .map((item) => {
    //       return `${item.Type},${item.Quantity},${item.UnitPriceSum},${item.TotalPriceSum},${item.freightSum},${item.dutySum},${item.gstSum},${item.totalPriceGstSum}`;
    //     })
    //     .join("\n");

    //   // Add the grand total row
    //   const summaryTotalRow = `\n\nTOTAL:,${totalQuantity},$ ${totalUnitPrice.toFixed(
    //     2
    //   )},$ ${totalPrice.toFixed(2)},$ ${totalFreight.toFixed(
    //     2
    //   )},$ ${totalDuty.toFixed(2)},$ ${totalGst.toFixed(
    //     2
    //   )}, $ ${SumOftotalPriceWithGst.toFixed(2)}\n\n\n`;

    //   const summarySheetTitle = `\nSUMMARY SHEET: \n`;
    //   const pricingDetailsTitle = `\nPRICING DETAILS: \n`;

    //   const grandTotalCsv = `\n\nGRAND TOTAL:,$ ${totalPrice.toFixed(2)}\n`; //total price without gst
    //   const grandTotalWithGST = `\nGRAND TOTAL With GST:,$ ${SumOftotalPriceWithGst.toFixed(
    //     2
    //   )}\n`; //total price with gst

    //   // Combine all parts into the final CSV
    //   const finalCsv =
    //     grandTotalCsv +
    //     grandTotalWithGST +
    //     buildQuantityEnteredByUser +
    //     summarySheetTitle +
    //     summaryHeader +
    //     summaryDatas +
    //     summaryTotalRow +
    //     pricingDetailsTitle +
    //     csvData;

    //   // Create a Blob and download the CSV file
    //   const blob = new Blob([finalCsv], { type: "text/csv;charset=utf-8;" });
    //   const link = document.createElement("a");
    //   const url = URL.createObjectURL(blob);
    //   link.setAttribute("href", url);
    //   link.setAttribute("download", this.bom[0].bom.bom_file_name);
    //   link.style.visibility = "hidden";
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },

    // function to calculate Summary sheet ------------------

    calculateSummary() {
      // CREATE INPUT DATA ARRAY FOR CALCULATING SUMMARY SHEET

      const csvDataString = this.localBom;
      console.log("Data from grid (CSV format):", csvDataString);
      const inputSummaryArray = [];

      // Loop through each row of csvData
      csvDataString.forEach((row) => {
        const componentType =
          row["line_item_type"] && row["line_item_type"].name
            ? row["line_item_type"].name
            : "Unknown";
        const unitPrice = parseFloat(row["unit_price"] || 0);
        const totalPrice = parseFloat(row["total_price"] || 0);

        // Calculate Freight, Duty, and GST
        const freight_percent = 0.12; // 12%
        const duty_percent = 0.11; // 11%
        const activeComponents_Conn_arr = [
          "IC",
          "MOSFET",
          "DIODE",
          "LED",
          "DISPLAY",
          "BATTERY",
          "BATT",
          "OSCILATOR",
          "OSC",
          "CONN",
        ];

        // Freight calculation
        const freight_price = totalPrice * freight_percent;

        // Duty calculation
        const duty_price = activeComponents_Conn_arr.includes(
          componentType.toUpperCase()
        )
          ? totalPrice * duty_percent
          : 0;

        // GST calculation
        const gst_price = (totalPrice + freight_price + duty_price) * 0.18; // 18%

        // Total price with GST calculation
        const totalPrice_gst =
          totalPrice + freight_price + duty_price + gst_price;

        console.log("component type: ", componentType);
        console.log("unit price: ", unitPrice);
        console.log("total price: ", totalPrice);
        console.log("freight: ", freight_price);
        console.log("duty : ", duty_price);
        console.log("GST: ", gst_price);
        console.log("total price with gst: ", totalPrice_gst);

        // Create an object for the current row with formatted price strings
        const formattedRow = {
          Type: componentType,
          "Unit Price": `$${unitPrice.toFixed(2)}`,
          "Total Price": `$${totalPrice.toFixed(2)}`,
          Freight: `$${freight_price.toFixed(2)}`,
          Duty: `$${duty_price.toFixed(2)}`,
          GST: `$${gst_price.toFixed(2)}`,
          "Total Price with GST": `$${totalPrice_gst.toFixed(2)}`, // Fixed key name
        };

        // Push the formatted row to summaryArray
        inputSummaryArray.push(formattedRow);
      });

      console.log("Input Summary Data: ", inputSummaryArray);

      // SUMMARY SHEET CALCULATION
      const summary = {};

      inputSummaryArray.forEach((item) => {
        const type = item["Type"];

        // Remove $ sign and parse
        const unitPrice =
          parseFloat(item["Unit Price"].replace(/^\$/, "")) || 0;
        const totalPrice =
          parseFloat(item["Total Price"].replace(/^\$/, "")) || 0;
        const freight = parseFloat(item["Freight"].replace(/^\$/, "")) || 0;
        const duty = parseFloat(item["Duty"].replace(/^\$/, "")) || 0;
        const gst = parseFloat(item["GST"].replace(/^\$/, "")) || 0; // Fixed key name
        const totalPrice_gst =
          parseFloat(item["Total Price with GST"].replace(/^\$/, "")) || 0; // Fixed key name

        // Initialize the summary for this type if it doesn't exist
        if (!summary[type]) {
          summary[type] = {
            Quantity: 0,
            UnitPriceSum: 0,
            TotalPriceSum: 0,
            freightSum: 0,
            dutySum: 0,
            gstSum: 0,
            totalPriceGstSum: 0,
          };
        }

        // Update the summary counts and sums
        summary[type].Quantity += 1; // Increment quantity
        summary[type].UnitPriceSum += unitPrice; // Sum of unit prices
        summary[type].TotalPriceSum += totalPrice; // Sum of total prices
        summary[type].freightSum += freight;
        summary[type].dutySum += duty;
        summary[type].gstSum += gst;
        summary[type].totalPriceGstSum += totalPrice_gst;
      });

      // Convert the summary object to an array for output
      const result = Object.keys(summary)
        .map((key) => ({
          Type: key,
          Quantity: summary[key].Quantity,
          UnitPriceSum: summary[key].UnitPriceSum,
          TotalPriceSum: summary[key].TotalPriceSum,
          freightSum: summary[key].freightSum,
          dutySum: summary[key].dutySum,
          gstSum: summary[key].gstSum,
          totalPriceGstSum: summary[key].totalPriceGstSum,
        }))
        // Sort by Total Price with GST in descending order
        .sort((a, b) => b.totalPriceGstSum - a.totalPriceGstSum);

      console.log("\n Final Summary Sheet : ---> \n", result);

      return result;
    },

    // calculateSummary() {
    //   // CREATE INPUT DATA ARRAY FOR CALCULATING SUMMARY SHEET

    //   const csvDataString = this.localBom;
    //   console.log("Data from grid (CSV format):", csvDataString);
    //   const inputSummaryArray = [];

    //   // Loop through each row of csvData
    //   csvDataString.forEach((row) => {
    //     const componentType =
    //       row["line_item_type"] && row["line_item_type"].name
    //         ? row["line_item_type"].name
    //         : "Unknown";
    //     const unitPrice = parseFloat(row["unit_price"] || 0);
    //     const totalPrice = parseFloat(row["total_price"] || 0);

    //     // Calculate Freight, Duty, and GST
    //     const freight_percent = 0.12; // 12%
    //     const duty_percent = 0.11; // 11%
    //     const activeComponents_Conn_arr = [
    //       "IC",
    //       "MOSFET",
    //       "DIODE",
    //       "LED",
    //       "DISPLAY",
    //       "BATTERY",
    //       "BATT",
    //       "OSCILATOR",
    //       "OSC",
    //       "CONN",
    //     ];

    //     // Freight calculation
    //     const freight_price = totalPrice * freight_percent;

    //     // Duty calculation
    //     const duty_price = activeComponents_Conn_arr.includes(
    //       componentType.toUpperCase()
    //     )
    //       ? totalPrice * duty_percent
    //       : 0;

    //     // GST calculation
    //     const gst_price = (totalPrice + freight_price + duty_price) * 0.18; // 18%

    //     // Total price with GST calculation
    //     const totalPrice_gst =
    //       totalPrice + freight_price + duty_price + gst_price;

    //     console.log("component type: ", componentType);
    //     console.log("unit price: ", unitPrice);
    //     console.log("total price: ", totalPrice);
    //     console.log("freight: ", freight_price);
    //     console.log("duty : ", duty_price);
    //     console.log("GST: ", gst_price);
    //     console.log("total price with gst: ", totalPrice_gst);

    //     // Create an object for the current row with formatted price strings
    //     const formattedRow = {
    //       Type: componentType,
    //       "Unit Price": `$${unitPrice.toFixed(2)}`,
    //       "Total Price": `$${totalPrice.toFixed(2)}`,
    //       Freight: `$${freight_price.toFixed(2)}`,
    //       Duty: `$${duty_price.toFixed(2)}`,
    //       GST: `$${gst_price.toFixed(2)}`,
    //       "Total Price with GST": `$${totalPrice_gst.toFixed(2)}`, // Fixed key name
    //     };

    //     // Push the formatted row to summaryArray
    //     inputSummaryArray.push(formattedRow);
    //   });

    //   console.log("Input Summary Data: ", inputSummaryArray);

    //   // SUMMARY SHEET CALCULATION
    //   const summary = {};

    //   inputSummaryArray.forEach((item) => {
    //     const type = item["Type"];

    //     // Remove $ sign and parse
    //     const unitPrice =
    //       parseFloat(item["Unit Price"].replace(/^\$/, "")) || 0;
    //     const totalPrice =
    //       parseFloat(item["Total Price"].replace(/^\$/, "")) || 0;
    //     const freight = parseFloat(item["Freight"].replace(/^\$/, "")) || 0;
    //     const duty = parseFloat(item["Duty"].replace(/^\$/, "")) || 0;
    //     const gst = parseFloat(item["GST"].replace(/^\$/, "")) || 0; // Fixed key name
    //     const totalPrice_gst =
    //       parseFloat(item["Total Price with GST"].replace(/^\$/, "")) || 0; // Fixed key name

    //     // Initialize the summary for this type if it doesn't exist
    //     if (!summary[type]) {
    //       summary[type] = {
    //         Quantity: 0,
    //         UnitPriceSum: 0,
    //         TotalPriceSum: 0,
    //         freightSum: 0,
    //         dutySum: 0,
    //         gstSum: 0,
    //         totalPriceGstSum: 0,
    //       };
    //     }

    //     // Update the summary counts and sums
    //     summary[type].Quantity += 1; // Increment quantity
    //     summary[type].UnitPriceSum += unitPrice; // Sum of unit prices
    //     summary[type].TotalPriceSum += totalPrice; // Sum of total prices
    //     summary[type].freightSum += freight;
    //     summary[type].dutySum += duty;
    //     summary[type].gstSum += gst;
    //     summary[type].totalPriceGstSum += totalPrice_gst;
    //   });

    //   // Convert the summary object to an array for output
    //   const result = Object.keys(summary).map((key) => ({
    //     Type: key,
    //     Quantity: summary[key].Quantity,
    //     UnitPriceSum: summary[key].UnitPriceSum,
    //     TotalPriceSum: summary[key].TotalPriceSum,
    //     freightSum: summary[key].freightSum,
    //     dutySum: summary[key].dutySum,
    //     gstSum: summary[key].gstSum,
    //     totalPriceGstSum: summary[key].totalPriceGstSum,
    //   }));

    //   console.log("\n Final Summary Sheet : ---> \n", result);

    //   return result;
    // },

    customColumnCalculation(params) {
      console.log(
        "\n customColumnCalculation Method is Triggered............."
      );

      // Freight Calculation
      const totalPrice = parseFloat(params.data.total_price) || 0;
      const calcFreight = totalPrice * 0.12; //12%
      // console.log("Freight value in function: ",calcFreight)

      // Duty Calculation
      let calcDuty = 0.0;
      const activeComponents_Conn_arr = [
        "IC",
        "MOSFET",
        "DIODE",
        "LED",
        "DISPLAY",
        "BATTERY",
        "BATT",
        "OSCILATOR",
        "OSC",
        "CONN",
      ];
      let componentType = "Unknown";
      if (params.data.line_item_type && params.data.line_item_type.name) {
        componentType = params.data.line_item_type.name;
        if (activeComponents_Conn_arr.includes(componentType.toUpperCase())) {
          calcDuty = totalPrice * 0.11; // 11%
        }
      } else {
        console.log(
          "line_item_type.name is either null, undefined, or empty => IN THIS BOM. "
        );
      }
      // console.log("Duty value in function: ",calcDuty)

      // GST Calculation
      const calcGST = (totalPrice + calcFreight + calcDuty) * 0.18; //18%
      // console.log("gst in function : ",calcGST)

      // Total Price With GST Calculation
      const calcTotalPriceWithGst =
        totalPrice + calcFreight + calcDuty + calcGST;
      // console.log("total price with GST : ", calcTotalPriceWithGst)

      const result = {
        PartNumber: params.data.part_number,
        ComponentType: componentType,
        TotalPrice: totalPrice,
        Freight_value: calcFreight,
        Duty_value: calcDuty,
        GST_value: calcGST,
        TotalPriceWithGst_value: calcTotalPriceWithGst,
      };
      console.log("the result of column calculation : ", result);
      return result;
    },
  },
};
</script>

<style scoped>
.grand-total {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.footer-label {
  margin-right: 10px;
  font-size: 18px;
  color: #007bff; /* Bootstrap primary color for label */
}

.footer-value {
  font-size: 20px;
  color: #28a745; /* Bootstrap success color for value */
}
</style>
